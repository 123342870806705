import { ready } from './ready'

function playVideo(event) {
    event.preventDefault(); // Prevent the link from navigating

    // Find the closest figure container to hold the videoContainer
    const figure_element = event.currentTarget.closest('.cp-ui-youtube-video');
    const youTubeId = figure_element.dataset.youTubeId;

    // Check if this figure already has a video playing
    if (figure_element.querySelector('.cp-ui-youtube-video__video')) {
        return; // If video already exists, do nothing
    }

    // Create iframe
    const video = document.createElement('iframe');
    video.className = 'cp-ui-youtube-video__video';
    video.allow = 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture';
    video.allowFullscreen = true;
    video.src = `https://www.youtube-nocookie.com/embed/${youTubeId}?autoplay=1`;

    // Create videoContainer and add iframe to it
    const videoContainer = document.createElement("div");
    videoContainer.className = 'cp-ui-youtube-video__media';
    videoContainer.appendChild(video);

    // Add videoContainer to the figure
    figure_element.appendChild(videoContainer);
}

ready(function () {
    // Select all video cover links
    const links = document.querySelectorAll('.cp-ui-youtube-video__cover');
    // Add click event listener to each link
    links.forEach(link => {
        link.addEventListener('click', playVideo);
    });
});
