export function ready(fn) {
  if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
    fn()
  } else if (typeof Turbolinks == 'undefined') {
    /* Listen for the DOMContentLoaded event */
    document.addEventListener('DOMContentLoaded', fn)
  } else {
    /* Listen for tubolinks:load - fires once after the initial page load, and again after every Turbolinks visit. */
    document.addEventListener('turbolinks:load', fn)
  }
}
